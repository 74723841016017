(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/banner_common/banner-helpers/assets/javascripts/other.js') >= 0) return;  svs.modules.push('/components/banner_common/banner-helpers/assets/javascripts/other.js');

'use strict';

function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
const isServer = typeof exports === 'object';
const trinidadCore = isServer && require('trinidad-core').core;


const escapeHtml = input => {
  const replacingChars = match => ({
    '&': '&amp;',
    '<': '&lt;',
    '>': '&gt;',
    '"': '&quot;'
  })[match];
  return input === null || input === void 0 ? void 0 : input.replace(/[&<>"]/g, replacingChars);
};
const getCallToAction = function (action) {
  let viewConfig = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  action = _objectSpread(_objectSpread({}, action), {}, {
    actionConfig: _objectSpread(_objectSpread({}, action.actionConfig), {}, {
      viewConfig
    })
  });
  return action;
};
const getIsAdmin = req => {
  const {
    roles
  } = (isServer ? req.userSession : svs.core.userSession) || {};
  return isServer ? req.userSession.hasRole(roles.INTERNAL) : svs.core.userSession.hasRole(roles.INTERNAL);
};
const getIsPreview = req => {
  const {
    roles
  } = isServer ? req.userSession : svs.core.userSession;
  return !isServer && svs.core.data.roles.includes(roles.INTERNAL);
};

const groupNumbers = function (text) {
  let config = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  let wrapper = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : '<span class="inline-block">$&</span>';
  const output = (text === null || text === void 0 ? void 0 : text.replaceAll(/\d+(?:[ .,:-]\d+)*/g, wrapper)) || '';
  return config.escapeHtml ? escapeHtml(output) : output;
};
const getContentType = type => (type === null || type === void 0 ? void 0 : type.split(':').pop()) || 'banner';
const fallbackFunction = () => '';
const getHbsCompiled = layoutType => {
  var _global, _svs$banner_layouts$l;
  const hbs = isServer && ((_global = global) === null || _global === void 0 || (_global = _global.internalInstances) === null || _global === void 0 ? void 0 : _global.get('hbs'));
  return isServer ? (hbs === null || hbs === void 0 ? void 0 : hbs.cache["components-banner_layouts-".concat(layoutType, "-").concat(layoutType)]) || fallbackFunction : ((_svs$banner_layouts$l = svs.banner_layouts[layoutType]) === null || _svs$banner_layouts$l === void 0 ? void 0 : _svs$banner_layouts$l.templates[layoutType]) || fallbackFunction;
};
const getNoscriptUrl = fallbackImg => fallbackImg === null || fallbackImg === void 0 ? void 0 : fallbackImg.url;
const getTrackingString = (inputTitle, layoutType, tracking) => {
  const format = isServer ? trinidadCore.components.require('utils', 'format').api : svs.utils.format;
  const title = format.safeString(inputTitle) || inputTitle;
  const {
    widget,
    position
  } = tracking;
  const output = [widget.widgetType, widget.trackingId, position, layoutType, title].join('/');
  return output;
};

const getRCTools = _ref => {
  var _res$locals, _svs$core, _model$layoutConfig;
  let {
    layoutType,
    model,
    res,
    req,
    tracking
  } = _ref;
  const contentType = getContentType(model.contentType);
  const hbsCompiled = getHbsCompiled(layoutType);
  const isAdmin = getIsAdmin(req);
  const isPreview = getIsPreview(req);
  const features = isServer ? (res === null || res === void 0 || (_res$locals = res.locals) === null || _res$locals === void 0 ? void 0 : _res$locals.features) || [] : ((_svs$core = svs.core) === null || _svs$core === void 0 || (_svs$core = _svs$core.data) === null || _svs$core === void 0 ? void 0 : _svs$core.features) || [];
  const trackingString = getTrackingString(model.title, layoutType, tracking);
  const defaultThemeClass = 'default-transparent';
  const themeClass = ((_model$layoutConfig = model.layoutConfig) === null || _model$layoutConfig === void 0 ? void 0 : _model$layoutConfig.theme) || defaultThemeClass;
  const isThemeClassDefault = themeClass === defaultThemeClass;
  const getCallToActions = viewConfig => [...model.callToActions].map(action => getCallToAction(action, viewConfig));
  return {
    contentType,
    features,
    getCallToActions,
    hbsCompiled,
    isAdmin,
    isPreview,
    isThemeClassDefault,
    themeClass,
    trackingString
  };
};
const other = {
  getCallToAction,
  getNoscriptUrl,
  getRCTools,
  groupNumbers
};
if (isServer) {
  module.exports = other;
} else {
  svs.banner_common.banner_helpers = _objectSpread(_objectSpread({}, svs.banner_common.banner_helpers), other);
}

 })(window);