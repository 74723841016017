(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/banner_common/banner-helpers/assets/javascripts/jackpots.js') >= 0) return;  svs.modules.push('/components/banner_common/banner-helpers/assets/javascripts/jackpots.js');

'use strict';

let currencyInMillions;
let productMapping;

function jackpots(_ref) {
  let {
    product,
    getJackpot
  } = _ref;
  if (product === 'rubbet') {
    const totalJackpotAmount = productMapping.getAllNumericIds().map(numericId => getJackpot(numericId)).reduce((sum, _ref2) => {
      let {
        jackpotAmount
      } = _ref2;
      return sum + jackpotAmount;
    }, 0);
    return {
      jackpotLabel: 'Totala toppvinster',
      jackpotAmountString: "ca ".concat(currencyInMillions(totalJackpotAmount), " milj kr")
    };
  }
  const {
    defaultJackpot,
    jackpotAmountStringShort,
    description
  } = getJackpot(product);
  return {
    jackpotLabel: defaultJackpot ? "".concat(description, " alltid minst") : "".concat(description, " just nu"),
    jackpotAmountString: jackpotAmountStringShort.replace('minst ', '')
  };
}
if (svs.isServer) {
  const trinidad = require('trinidad-core').core;
  currencyInMillions = trinidad.components.require('utils', 'format').api.CurrencyInMillions;
  productMapping = trinidad.components.require('numbergames', 'product-mapping').productMapping;
  module.exports = {
    jackpots
  };
} else {
  currencyInMillions = svs.utils.format.CurrencyInMillions;
  productMapping = svs.numbergames.productMapping;
  svs.banner_common.banner_helpers.jackpots = jackpots;
}

 })(window);